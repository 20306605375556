body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
}

img {
  display: block;
}

a {
/*   color: #9B30FF; */
  color: #a0f;
  text-decoration: none;
}

a:hover {
/*   border-bottom: 3px solid #9B30FF; */
  border-bottom: 3px solid #a0f;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

span.google-text {
  display: none;
}

span.google-text:before {
  content: "<span class=\"google-font-blue\">G</span><span class=\"google-font-red\">o</span><span class=\"google-font-yellow\">o</span><span class=\"google-font-blue\">g</span><span class=\"google-font-green\">l</span><span class=\"google-font-red\">e</span>";
}

.google-font-red {
  color: #db4437!important;
}
.google-font-blue {
  color: #4285f4!important;
}
.google-font-green {
  color: #0f9d58!important;
}
.google-font-yellow {
  color: #f4b400!important;
}

main {
  margin: 0 auto;
  padding: 0 2rem;
}

.container {
  max-width: 52rem;
  margin-left: auto!important;
  margin-right: auto!important;
}

.container.large {
  max-width: 82rem;
}

p {
  margin: 1.75rem auto;
}

hr {
  border: none;
  border-bottom: 1px solid #bdbdbd;
}

.main-section {
  margin-top: 0;
  padding-top: 3.5rem;
}

.section-title {
  font-size: 2rem;
  margin: 0;
  padding: 0 0 1.75rem;
  line-height: 1.35;
}

.section-subtitle {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 4px;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.profile-pic {
  border-radius: 50%;
  /* position: absolute; */
  top: -2.5rem;
  /* left: 3.5rem; */
  /* width: 100px; */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 100px;
}
.site-navigation ul {
  padding: 0;
  display: flex;
  list-style: none;
}

.site-navigation li a {
  color: inherit;
  opacity: 0.7;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 1px solid transparent;
}

.site-navigation li:hover a {
  opacity: 1;
  border: 1px solid #4158D0;
  background: rgba(245,245,245,1);
}
.site-header {
  display: flex;
  justify-content: space-between;
  padding: 3.5rem;
}

.site-title {
  color: inherit;
  font-size: 0.5rem;
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  /* border-radius: 50px; */
  /* border: 2px solid transparent; */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.site-title:hover {
  text-shadow: 5px 5px 15px rgba(0,0,0,0.8);
  border: none;
}

.login-button {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  /* background: red; */
  top: 0;
  right: 0;
  z-index: 1000;
}

.login-button:hover {
  cursor: pointer;
  border-bottom: none;
}
.site-footer {
  border-top: 1px solid #dbdbdb;
  -webkit-border-image: -webkit-linear-gradient(47deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%) 30;
          border-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%) 30;
  margin-top: 7rem;
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
}

.copyright {
  text-align: right;
  padding: 1rem 0;
  /* color: #777; */
  /* color: white; */
  font-weight: 300;
  font-size: 0.8125rem;
}

.farewell {
  font-size: 2rem;
  line-height: 3.2rem;
  padding-top: 2rem;
  text-align: center;
  font-weight: 700;
  right: 0%;
}
.site-title.huge {
  margin: 0rem 0 3rem;
  padding: 0;
  font-size: 3rem;
  text-align: center;
  font-weight: 100;
  /* background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%); */
  background-image: -webkit-linear-gradient(47deg, #4158D0 0%, #4158D0 44%);
  background-image: linear-gradient(43deg, #4158D0 0%, #4158D0 44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 5rem;
}
.site-title.huge strong {
  font-weight: 700;
  background-image: -webkit-linear-gradient(47deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 32rem) {
  .hide-on-medium {
    display: none;
  }
}

@media screen and (min-width: 36rem) {
  .site-title.huge {
    font-size: 4rem;
    margin: 1rem 0 4rem;
  }
}

@media screen and (min-width: 48rem) {
  .site-title.huge {
    font-size: 5rem;
    margin: 2rem 0 5rem;
  }
}

@media screen and (min-width: 60rem) {
  .hide-on-large {
    display: none;
  }
  .site-title.huge {
    font-size: 6rem;
    margin: 3rem 0 6rem;
  }
}

.site-title.huge:hover {
  border-color: transparent;
  text-shadow: none;
}

.intro {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 700;
  margin: 5.25rem 0;
}

.self-intro span {
  position: absolute;
  background: #ffcc00;
  width: 520px;
  max-width: 100%;
  font-weight: 500;
}

.self-intro .flash span {
  padding: 0 0.5rem;
}

.section-talk-title {
  font-size: 2rem;
  line-height: 3.2rem;
}

.intro-sites {
  list-style: none;
  display: flex;
  margin: 3rem -0.25rem;
  height: 8rem;
}

.intro-site-item {
  flex: 1 1 15%;
  margin: 0.25rem;
  padding: 5rem 1rem 0;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1;
  background: gray;
}

.intro-site-item a {
  color: white;
  font-weight: 300;
  text-decoration: none;
  bottom: 0;
  z-index: 1000;
  /* text-indent: 200%; */
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.intro-site-item:hover {
  opacity: 0.8;
}

.intro .profile-pic.logged-in {
  border: 2px solid #a0f;
  border-image-source: linear-gradient(#a0f, #fff);
  border-image-slice: 30;
}

.my-links {
  list-style: none;
}

.my-link {
  display: inline-block;
  position: relative;
  margin-right: 1.5rem;
}

.my-link:last-child {
  margin-right: 0;
}

.my-link a,
.my-link span {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: normal;
  font-size: 2rem;
  display: inline-block;
}

.my-link span {
  opacity: 0.75;
}

.my-link a p,
.my-link span p {
  color: #000;
  color: initial;
  font-weight: 300;
  font-size: 0.9rem;
  position: absolute;
  line-height: 1.35;
  text-align: center;
  margin: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: none;
}

.my-link a:hover,
.my-link span:hover {
  border: none;
  opacity: 1;
  /* font-weight: bold; */
  /* text-shadow: 1px 1px 5px rgba(0,0,0,0.2); */
}

.my-link a:hover p,
.my-link span:hover p {
  display: block;
}
.latest-talk {
  border-bottom: 3px solid #a0f;
}

.latest-talk:before {
  position: absolute;
  top: -1px;
  right: -1px;
  display: block;
  width: 0;
	height: 0;
	border-top: 90px solid #a0f;
	border-left: 90px solid transparent;
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
}

@media screen and (min-width: 70rem) {
  .latest-talk:before {
    right: calc( 7rem - 1px ); 
  }
}

.latest-talk:after {
  position: absolute;
  z-index: 3;
  top: 1.2rem;
  right: 0;
  display: block;
  content: "Latest";
  color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #a0f;
  height: 1.2rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  width: 60px;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);

}

.latest-talk-image {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

@media screen and (max-width: 52rem) {
  .latest-talk-image {
    margin-left: -2rem;
    margin-right: -2rem;
    width: 100vw;
  }

  .latest-talk:before,
  .latest-talk:after {
    right: -2rem;
  }
}

@media screen and ( min-width: 70rem ) {
  .latest-talk-image {
    margin-left: -7rem;
    margin-right: -7rem;
    width: calc( 100% + 14rem );
  }

  .latest-talk:before,
  .latest-talk:after {
    right: -7rem;
  }
}

.list-index {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-index:first-of-type {
  /* margin-top: 1.75rem; */
  border-top: 1px solid #eee;
}

.list-index:last-of-type {
  margin-bottom: 1.75rem;
}

.list-index-item,
.latest-talk {
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
}

.latest-talk:hover a,
.list-index-item:hover a {
  border: none;
  box-shadow: inset 0px -3px 0px #a0f;
  background: #f3f3f3;
}

.latest-talk:hover img {
  box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
}

.list-index-item:first-child {
  margin-top: -1px;
}

.list-index-item a,
.latest-talk a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 1rem 0;
  line-height: 1.35;
}

.list-index-date {
  display: none;
  position: absolute;
  top: 0em;
  right: 0;
  z-index: 2;
  background: #a0f;
  color: white;
  padding: 0.25rem;
  font-size: 0.6rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.list-index-new .talk-title:before,
.list-index-upcoming .talk-title:before,
.list-index-updated .talk-title:before {
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  letter-spacing: 0.25px;
  content: "New";
  display: inline;
  z-index: 2;
  background: #a0f;
  color: white;
  padding: 0.25rem;
  font-size: 0.6rem;
  font-weight: 400;
/*   letter-spacing: 0.25px; */
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-transform: uppercase;
}

.list-index-new .talk-title {
  padding-left: 2.75rem;
}

.list-index-updated .talk-title,
.list-index-upcoming .talk-title  {
  padding-left: 4.5rem;
}

.list-index-new .talk-date,
.list-index-updated .talk-date,
.list-index-upcoming .talk-date {
  color: #a0f;
}

.list-index-updated .talk-title:before {
  content: "Updated";
}

.list-index-upcoming .talk-title:before {
  content: "Upcoming";
}

.list-intro {
  margin-bottom: -1rem;
  padding-bottom: 0;
}

.list-level-two {
  margin-top: -1rem;
}

.talk-title {
  font-weight: bolder;
  padding-right: 0.5rem;
}

.talk-location {
  font-style: oblique;
  font-size: 0.8em;
}

.talk-date {
  float: right;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #777;
  font-weight: 300;
}
.classes-section {
  /* margin-top: 1.75rem; */
  display: flex;
  justify-content: space-between;
}

.tech-classes,
.esl-classes {
  flex: 1 1 50%;
}
.tech-classes {
  margin-right: 1rem;
}
.esl-classes {
  margin-left: 1rem;
}

.class-curriculum,
.class-dates {
  display: none;
}
.list-tiles {
  list-style: none;
}

.list-tiles:first-of-type{
  margin-top: .75rem;
}

.list-tile-item {
  padding: 0.125rem 0;
}

.list-tile-item:hover {
  background: #f3f3f3;
}

.list-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-gap: 1rem; */
  list-style: none;
  /* margin-top: 1.75rem; */
  background-image: -webkit-linear-gradient(47deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
}

#projects figure {
  position: relative;
  max-height: 280px;
  overflow: hidden;
}

figure.effect {
  /* background: #22a39f;
  background: #4158D0;
  background: #C850C0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  background: transparent; */
  color: white;
  border: 0.6rem solid white;
  margin: -0.1rem;
}

#projects figure img {
  width: 100%;
}

figure.effect:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
figure.effect img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

#projects figure figcaption {
  padding: 20px 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

figure.effect figcaption {
  text-align: left;
}

#projects figure h3 {
  color: #FFF;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 1.4rem;
  line-height: 1.35;
  /* padding-top: 10px; */
}
figure.effect:hover h3 {
  opacity: 1;
}
figure.effect h3 {
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect:hover h3::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
figure.effect h3::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

figure.effect:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect p {
  padding-top: 10px;
  line-height: 1.35;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

h3.project-title i {
  font-weight: normal;
}

#projects figure p {
  margin: 0;
}
#projects figure p > strong {
  width: 60px;
  display: inline-block;
  margin: 0px;
}

/* Fake wrap the box in an anchor tag */
#projects figure a.visit {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

#projects figure .icon {
  background: #22A39F;
  background: #aa00ff;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  text-align: center;
  padding-top: 6px;
}

.list-icons-container {
  margin: 0 auto;
  text-align: center;
}

.list-icons {
  margin: 0 auto;
  list-style: none;
  text-align: center;
}

.list-icons-item {
  font-size: 1.6rem;
  display: inline;
  opacity: 0.8;
  margin: 1.5rem;
}
#about .section-title {
  padding-bottom: 0;
}
