.list-tiles {
  list-style: none;
}

.list-tiles:first-of-type{
  margin-top: .75rem;
}

.list-tile-item {
  padding: 0.125rem 0;
}

.list-tile-item:hover {
  background: #f3f3f3;
}

.list-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-gap: 1rem; */
  list-style: none;
  /* margin-top: 1.75rem; */
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
}

#projects figure {
  position: relative;
  max-height: 280px;
  overflow: hidden;
}

figure.effect {
  /* background: #22a39f;
  background: #4158D0;
  background: #C850C0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  background: transparent; */
  color: white;
  border: 0.6rem solid white;
  margin: -0.1rem;
}

#projects figure img {
  width: 100%;
}

figure.effect:hover img {
  opacity: 0.2;
  transform: scale(1.1);
}
figure.effect img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

#projects figure figcaption {
  padding: 20px 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

figure.effect figcaption {
  text-align: left;
}

#projects figure h3 {
  color: #FFF;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 1.4rem;
  line-height: 1.35;
  /* padding-top: 10px; */
}
figure.effect:hover h3 {
  opacity: 1;
}
figure.effect h3 {
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect:hover h3::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
figure.effect h3::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

figure.effect:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect p {
  padding-top: 10px;
  line-height: 1.35;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

h3.project-title i {
  font-weight: normal;
}

#projects figure p {
  margin: 0;
}
#projects figure p > strong {
  width: 60px;
  display: inline-block;
  margin: 0px;
}

/* Fake wrap the box in an anchor tag */
#projects figure a.visit {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

#projects figure .icon {
  background: #22A39F;
  background: #aa00ff;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  text-align: center;
  padding-top: 6px;
}

.list-icons-container {
  margin: 0 auto;
  text-align: center;
}

.list-icons {
  margin: 0 auto;
  list-style: none;
  text-align: center;
}

.list-icons-item {
  font-size: 1.6rem;
  display: inline;
  opacity: 0.8;
  margin: 1.5rem;
}