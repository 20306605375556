.latest-talk {
  border-bottom: 3px solid #a0f;
}

.latest-talk:before {
  position: absolute;
  top: -1px;
  right: -1px;
  display: block;
  width: 0;
	height: 0;
	border-top: 90px solid #a0f;
	border-left: 90px solid transparent;
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
}

@media screen and (min-width: 70rem) {
  .latest-talk:before {
    right: calc( 7rem - 1px ); 
  }
}

.latest-talk:after {
  position: absolute;
  z-index: 3;
  top: 1.2rem;
  right: 0;
  display: block;
  content: "Latest";
  color: white;
  transform: rotate(45deg);
  background: #a0f;
  height: 1.2rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  width: 60px;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);

}

.latest-talk-image {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

@media screen and (max-width: 52rem) {
  .latest-talk-image {
    margin-left: -2rem;
    margin-right: -2rem;
    width: 100vw;
  }

  .latest-talk:before,
  .latest-talk:after {
    right: -2rem;
  }
}

@media screen and ( min-width: 70rem ) {
  .latest-talk-image {
    margin-left: -7rem;
    margin-right: -7rem;
    width: calc( 100% + 14rem );
  }

  .latest-talk:before,
  .latest-talk:after {
    right: -7rem;
  }
}

.list-index {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-index:first-of-type {
  /* margin-top: 1.75rem; */
  border-top: 1px solid #eee;
}

.list-index:last-of-type {
  margin-bottom: 1.75rem;
}

.list-index-item,
.latest-talk {
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
}

.latest-talk:hover a,
.list-index-item:hover a {
  border: none;
  box-shadow: inset 0px -3px 0px #a0f;
  background: #f3f3f3;
}

.latest-talk:hover img {
  box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
}

.list-index-item:first-child {
  margin-top: -1px;
}

.list-index-item a,
.latest-talk a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 1rem 0;
  line-height: 1.35;
}

.list-index-date {
  display: none;
  position: absolute;
  top: 0em;
  right: 0;
  z-index: 2;
  background: #a0f;
  color: white;
  padding: 0.25rem;
  font-size: 0.6rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.list-index-new .talk-title:before,
.list-index-upcoming .talk-title:before,
.list-index-updated .talk-title:before {
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  letter-spacing: 0.25px;
  content: "New";
  display: inline;
  z-index: 2;
  background: #a0f;
  color: white;
  padding: 0.25rem;
  font-size: 0.6rem;
  font-weight: 400;
/*   letter-spacing: 0.25px; */
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  text-transform: uppercase;
}

.list-index-new .talk-title {
  padding-left: 2.75rem;
}

.list-index-updated .talk-title,
.list-index-upcoming .talk-title  {
  padding-left: 4.5rem;
}

.list-index-new .talk-date,
.list-index-updated .talk-date,
.list-index-upcoming .talk-date {
  color: #a0f;
}

.list-index-updated .talk-title:before {
  content: "Updated";
}

.list-index-upcoming .talk-title:before {
  content: "Upcoming";
}

.list-intro {
  margin-bottom: -1rem;
  padding-bottom: 0;
}

.list-level-two {
  margin-top: -1rem;
}

.talk-title {
  font-weight: bolder;
  padding-right: 0.5rem;
}

.talk-location {
  font-style: oblique;
  font-size: 0.8em;
}

.talk-date {
  float: right;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #777;
  font-weight: 300;
}