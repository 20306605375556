.site-header {
  display: flex;
  justify-content: space-between;
  padding: 3.5rem;
}

.site-title {
  color: inherit;
  font-size: 0.5rem;
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  /* border-radius: 50px; */
  /* border: 2px solid transparent; */
  transition: all 0.3s;
}

.site-title:hover {
  text-shadow: 5px 5px 15px rgba(0,0,0,0.8);
  border: none;
}

.login-button {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  /* background: red; */
  top: 0;
  right: 0;
  z-index: 1000;
}

.login-button:hover {
  cursor: pointer;
  border-bottom: none;
}