* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
}

img {
  display: block;
}

a {
/*   color: #9B30FF; */
  color: #a0f;
  text-decoration: none;
}

a:hover {
/*   border-bottom: 3px solid #9B30FF; */
  border-bottom: 3px solid #a0f;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

span.google-text {
  display: none;
}

span.google-text:before {
  content: "<span class=\"google-font-blue\">G</span><span class=\"google-font-red\">o</span><span class=\"google-font-yellow\">o</span><span class=\"google-font-blue\">g</span><span class=\"google-font-green\">l</span><span class=\"google-font-red\">e</span>";
}

.google-font-red {
  color: #db4437!important;
}
.google-font-blue {
  color: #4285f4!important;
}
.google-font-green {
  color: #0f9d58!important;
}
.google-font-yellow {
  color: #f4b400!important;
}

main {
  margin: 0 auto;
  padding: 0 2rem;
}

.container {
  max-width: 52rem;
  margin-left: auto!important;
  margin-right: auto!important;
}

.container.large {
  max-width: 82rem;
}

p {
  margin: 1.75rem auto;
}

hr {
  border: none;
  border-bottom: 1px solid #bdbdbd;
}

.main-section {
  margin-top: 0;
  padding-top: 3.5rem;
}

.section-title {
  font-size: 2rem;
  margin: 0;
  padding: 0 0 1.75rem;
  line-height: 1.35;
}

.section-subtitle {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 4px;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.profile-pic {
  border-radius: 50%;
  /* position: absolute; */
  top: -2.5rem;
  /* left: 3.5rem; */
  /* width: 100px; */
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
}