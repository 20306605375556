.site-navigation ul {
  padding: 0;
  display: flex;
  list-style: none;
}

.site-navigation li a {
  color: inherit;
  opacity: 0.7;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 1px solid transparent;
}

.site-navigation li:hover a {
  opacity: 1;
  border: 1px solid #4158D0;
  background: rgba(245,245,245,1);
}