.site-title.huge {
  margin: 0rem 0 3rem;
  padding: 0;
  font-size: 3rem;
  text-align: center;
  font-weight: 100;
  /* background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%); */
  background-image: linear-gradient(43deg, #4158D0 0%, #4158D0 44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 5rem;
}
.site-title.huge strong {
  font-weight: 700;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 44%, #FFCC70 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 32rem) {
  .hide-on-medium {
    display: none;
  }
}

@media screen and (min-width: 36rem) {
  .site-title.huge {
    font-size: 4rem;
    margin: 1rem 0 4rem;
  }
}

@media screen and (min-width: 48rem) {
  .site-title.huge {
    font-size: 5rem;
    margin: 2rem 0 5rem;
  }
}

@media screen and (min-width: 60rem) {
  .hide-on-large {
    display: none;
  }
  .site-title.huge {
    font-size: 6rem;
    margin: 3rem 0 6rem;
  }
}

.site-title.huge:hover {
  border-color: transparent;
  text-shadow: none;
}

.intro {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 700;
  margin: 5.25rem 0;
}

.self-intro span {
  position: absolute;
  background: #ffcc00;
  width: 520px;
  max-width: 100%;
  font-weight: 500;
}

.self-intro .flash span {
  padding: 0 0.5rem;
}

.section-talk-title {
  font-size: 2rem;
  line-height: 3.2rem;
}

.intro-sites {
  list-style: none;
  display: flex;
  margin: 3rem -0.25rem;
  height: 8rem;
}

.intro-site-item {
  flex: 1 1 15%;
  margin: 0.25rem;
  padding: 5rem 1rem 0;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1;
  background: gray;
}

.intro-site-item a {
  color: white;
  font-weight: 300;
  text-decoration: none;
  bottom: 0;
  z-index: 1000;
  /* text-indent: 200%; */
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.intro-site-item:hover {
  opacity: 0.8;
}

.intro .profile-pic.logged-in {
  border: 2px solid #a0f;
  border-image-source: linear-gradient(#a0f, #fff);
  border-image-slice: 30;
}

.my-links {
  list-style: none;
}

.my-link {
  display: inline-block;
  position: relative;
  margin-right: 1.5rem;
}

.my-link:last-child {
  margin-right: 0;
}

.my-link a,
.my-link span {
  transition: all 0.3s;
  font-weight: normal;
  font-size: 2rem;
  display: inline-block;
}

.my-link span {
  opacity: 0.75;
}

.my-link a p,
.my-link span p {
  color: initial;
  font-weight: 300;
  font-size: 0.9rem;
  position: absolute;
  line-height: 1.35;
  text-align: center;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.my-link a:hover,
.my-link span:hover {
  border: none;
  opacity: 1;
  /* font-weight: bold; */
  /* text-shadow: 1px 1px 5px rgba(0,0,0,0.2); */
}

.my-link a:hover p,
.my-link span:hover p {
  display: block;
}